<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card card-custom card-sticky">
          <notifications group="notification" position="top right" />
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Add New Loyalty Card</h3>
            </div>
            <div class="card-toolbar">
              <router-link
                to="/loyalty/cards/list"
                class="btn btn-light-primary font-weight-bolder mr-2"
              >
                <i class="ki ki-long-arrow-back icon-sm"></i>
                All Cards
              </router-link>
              <div class="js-submit-options btn-group">
                <button
                  @click="postLoyaltyCard"
                  type="button"
                  class="btn btn-primary font-weight-bolder"
                >
                  <i class="ki ki-check icon-sm"></i>
                  Add Card
                </button>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-xl-2"></div>
              <div class="col-xl-8">
                <div class="my-5">
                  <h3 class="text-dark font-weight-bold mb-10">Info:</h3>
                  <div class="form-group row">
                    <label class="col-3 mt-2">Card serial number: </label>
                    <div class="col-9">
                      <input
                        v-model="card.serialNumber"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 mt-2">Saldo: </label>
                    <div class="col-9">
                      <input
                        v-model.number="card.saldo"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 mt-2">Bonus saldo: </label>
                    <div class="col-9">
                      <input
                        v-model.number="card.saldoBonus"
                        class="form-control form-control-solid"
                        type="text"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-3 mt-2">Is Active: </label>
                    <div class="col-9">
                      <VtSwitch
                        @checkedEvent="updateIsActive"
                        :is-checked="card.isActive"
                        classes="switch-icon switch-outline switch-primary"
                        name="cardIsActive"
                      />
                    </div>
                  </div>
                  <div class="separator separator-dashed my-10"></div>
                  <h3 class="text-dark font-weight-bold mb-10">Owner:</h3>
                  <div class="form-group row">
                    <label class="col-3 mt-3">Owner: </label>
                    <div class="col-9">
                      <vSelect
                        v-model="card.owner"
                        class="form-control form-control-solid form-control--owner-select"
                        placeholder="Select owner"
                        :options="owners"
                        :reduce="(owner) => owner['@id']"
                        label="name"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-2"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <b-modal
          id="owner_modal"
          body-class="text-left"
          centered
          hide-header
          hide-footer
        >
          <div>
            <div class="row">
              <b-button
                class="btn btn-light-primary mb-3"
                block
                @click="$bvModal.hide('owner_modal')"
                >Close</b-button
              >
              <div class="col-12">
                <div class="form-group">
                  <label for="ownerName">Name</label>
                  <input
                    v-model="newOwner.title"
                    id="ownerName"
                    class="form-control form-control-solid"
                    placeholder="Enter owner name"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <button class="btn btn-block btn-primary">Add Owner</button>
              </div>
            </div>
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import VtSwitch from '@/view/content/components/switch/VtSwitch'
import vSelect from 'vue-select'
import axios from 'axios'

export default {
  name: 'LoyaltyCardNew',
  components: {
    VtSwitch,
    vSelect,
  },
  data() {
    return {
      card: {
        serialNumber: null,
        isActive: true,
        isVerified: false,
        saldo: 0,
        owner: null,
        user: null,
        isBonusApplied: false,
        saldoBonus: 0,
      },
      newOwner: {
        name: null,
      },
      user: {
        email: null,
        plainPassword: null,
        roles: ['ROLE_LOYALTY, ROLE_USER'],
      },
      checkPassword: null,
      owners: [],
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Loyalty New Card', route: '' },
    ])

    axios
      .get('https://loyalty.vendingtycoon.eu/api/v1/owners')
      .then(({ data }) => {
        this.owners = [...data['hydra:member']]
      })
  },
  methods: {
    postLoyaltyCard() {
      axios
        .post('https://loyalty.vendingtycoon.eu/api/v1/cards', this.card)
        .then(() => {
          setTimeout(() => {
            this.$router.push({ name: 'loyalty-cards-list' })
          }, 200)
        })
    },
    updateIsActive() {
      this.card.isActive = !this.card.isActive
    },
  },
}
</script>

<style lang="scss">
@import 'vue-select/src/scss/vue-select.scss';

.form-control {
  &--owner-select {
    height: unset !important;

    .vs__dropdown-toggle {
      padding: 0;
      border: 0;
      border-radius: 0;
    }
    .vs__dropdown-menu {
      border: 1px solid #e4e6ef;
      -webkit-box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);
      box-shadow: 0px 0px 50px 0px rgba(82, 63, 105, 0.15);

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #888;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }

      li {
        padding: 0.65rem 1rem;

        &:hover {
          background: #f3f6f9;
          color: #3f4254;
        }
      }
    }

    .vs__open-indicator {
    }
    .vs__dropdown-toggle {
    }
    .vs__clear {
      display: none;
    }
    .vs__selected {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0.25rem 0.75rem;
      font-size: 1rem;
      margin: 0.1rem 0.4rem 0.1rem 0;
      position: relative;
      float: left;
      color: #3f4254;
      background: #f3f6f9;
      border: 0;
      border-radius: 0.42rem;
    }
    .vs__no-options {
    }
    .vs__spinner {
    }
    .vs__deselect {
    }
    .vs__active {
    }
    .vs__dropdown-option--highlight {
      background: #ebedf3;
      color: #3f4254;
    }
  }
}
</style>
